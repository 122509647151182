import React from 'react';
import './legalesMentions.css'

const LegalNotice = () => {

    return (
        <div className='app'>
        <h1>Mentions légales/Legal notice</h1>
        <p><i>The following conditions are written in French. If you need translations of these, please contact us: contact@gimli-med.io.</i></p>
        <h4>Editeur</h4>
        <p>GIMLI est une SASU au capital social de 4 031,8 euros, immatriculée au Registre du Commerce et des Sociétés de Paris sous le numéro 910 410 109, dont le siège social est situé au 231 rue Saint Honoré 75001 PARIS.</p>
        <h4>Directeur de la publication</h4>
        <p>Monsieur Alexandre Yazigi, Président de la SAS GIMLI.</p>
        <h4>Hébergeur</h4>
        <p>Ce site web est hébergé par la société Netlify (https://www.netlify.com/).</p>
        <p>L'application développée par Gimli est hébergée sur les serveurs français d'OVH Cloud (https://www.ovhcloud.com/fr/).</p>
        <h2>Politique d'utilisation des données personnelles</h2>
        <h4>Identité du responsable du traitement</h4>
        <p>La société GIMLI.</p>
        <p>&laquo; Site &raquo; désigne la site internet gimli-med.io.</p>
        <h4>Coordonnées du contact CNIL de GIMLI</h4>
        <p>Adresse électronique : contact@gimli-med.io</p>
        <p>Adresse postale : GIMLI, 231 rue Saint Honoré 75001 PARIS</p>
        <h4>Finalités du traitement des données à caractère personnel</h4>
        <p>GIMLI collecte vos données à caractère personnel lors de votre navigation sur le Site, sur la base de votre consentement, soit de manière automatique, au moyen des cookies, soit via les formulaires de saisie d'informations présentés sur le Site. Vos données à caractère personnel sont donc collectées lorsque vous :</p>
        <ul>
            <li>
                <p>consultez les différentes pages du Site ;</p>
            </li>
            <li>
                <p>vous inscrivez à notre newsletter ;</p>
            </li>
            <li>
                <p>téléchargez un case study ou une étude ;</p>
            </li>
            <li>
                <p>contactez le service clients.</p>
            </li>
        </ul>
        <p>En ce qui concerne les formulaires de saisie d'informations affichés sur le Site, les données à caractère personnel dont la communication est obligatoire pour la prise en compte de votre demande sont identifiées par un astérisque &laquo; * &raquo;. Le défaut de communication d'une donnée personnelle identifiée comme obligatoire provoquera la non validation du formulaire en question. GIMLI ne pourra donc pas donner suite à votre demande.</p>
        <p>Les finalités des traitements que GIMLI effectue sont les suivantes :</p>
        <ul>
            <li>
                <p>envoi de prospection commerciale ;</p>
            </li>
            <li>
                <p>gestion des relations avec nos prospects ;</p>
            </li>
            <li>
                <p>réalisation de statistiques d'audience.</p>
            </li>
        </ul>
        <h4>Cookies</h4>
        <p>Les données à caractère personnel qui sont obtenues automatiquement à l'occasion de votre connexion et votre navigation sur le Site, notamment l'adresse IP de votre terminal, et/ou par l'intermédiaire de fichiers informatiques stockés sur votre terminal, communément appelés &laquo; cookies &raquo;, sont utilisées conformément aux modalités ci-dessous.</p>
        <h4>Les cookies de mesure d'audience et de statistiques</h4>
        <p>Ces cookies recueillent des informations sur la façon dont les visiteurs utilisent un site internet. Par exemple, le nombre de visites, les pages les plus visitées, etc.</p>
        <p>L'adresse IP collectée permet d'identifier la ville depuis laquelle vous vous connectez, cependant, les deux derniers octets (contenant les informations sur la localisation précise) sont immédiatement anonymisés pour ne pas permettre d'identifier un individu via les données collectées.</p>
        <p>Les données recueillies ne sont en aucun cas cédées à des tiers ni utilisées à d'autres fins que statistiques par GIMLI.</p>
        <h4>Comment gérer les cookies ?</h4>
        <p>Plusieurs possibilités vous sont offertes pour gérer les cookies. Tout paramétrage que vous pouvez entreprendre sera susceptible de modifier votre navigation sur Internet et vos conditions d'accès à certains services nécessitant l'utilisation de Cookies.</p>
        <p>Vous pouvez faire le choix à tout moment d'exprimer et de modifier vos souhaits en matière de cookies, par les moyens décrits ci-dessous. La configuration de chaque navigateur est différente. Elle est décrite dans le menu d'aide de votre navigateur.</p>
        <ul>
            <li>
                <p>Pour Chrome, <a href="https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en">suivez ce lien</a>.</p>
            </li>
            <li>
                <p>Pour Internet Explorer, <a href="http://support.mozilla.org/fr/kb/cookies-informations-sites-enregistrent?redirectlocale=fr&redirectslug=G%E9rer+les+cookies">suivez ce lien</a>.</p>
            </li>
            <li>
                <p>our Opera, <a href="http://windows.microsoft.com/fr-fr/internet-explorer/delete-manage-cookies">suivez ce lien</a>.</p>
            </li>
            <li>
                <p>Pour Chrome, <a href="https://help.opera.com/en/latest/web-preferences/#cookies">suivez ce lien</a>.</p>
            </li>
        </ul>
        <p>Pour plus d'informations sur les cookies, vous pouvez consulter le site de la CNIL,<a href="http://www.cnil.fr/vos-droits/vos-traces/les-cookies/">en cliquant sur ce lien</a>.</p>
        <h4>Destinataires des données</h4>
        <p>CLes données à caractère personnel collectées sur le Site sont réservées à l'usage de GIMLI. Elles peuvent être communiquées à nos prestataires ou fournisseurs qui interviennent dans la gestion du Site (gestionnaire technique et hébergeur du Site, fournisseur du service de mesures d'audience notamment).</p>
        <h4>Durée de conservation</h4>
        <p>GIMLI conserve les données à caractère personnel pendant une durée de trois ans à compter de la fin des relations commerciales avec le client et trois ans à compter du dernier contact émanant avec le prospect.</p>
        <h4>Droit d'accès, de rectification, de suppression et de portabilité des données</h4>
        <p>Conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, modifiée et au règlement européen &laquo; 2016/679/ UE du 27 Avril 2016 &raquo; relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données, vous bénéficiez d'un droit d'accès, d'opposition, de rectification, de suppression et de portabilité de vos données personnelles. Vous pouvez exercer votre droit en vous adressant par e-mail à contact@gimli-med.io, ou par courrier à l'adresse suivante :</p>
        <p>GIMLI, 231 rue Saint Honoré 75001 PARIS, France</p>
        <p>Votre demande devra indiquer votre nom et prénom, adresse e-mail ou postale, être signée et accompagnée d'un justificatif d'identité en cours de validité.</p>
        <p>Vous pouvez également vous opposer à l'envoi des informations commerciales en cliquant sur le lien de désabonnement présent dans chaque envoi.</p>
        </div>
    );
}

export default LegalNotice