import React from 'react';
import './legalesMentions.css'

const TermsOfUse = () => {

    return (
        <div className='app'>
            <h1>Conditions générales d'utilisation/Terms of use</h1>
            <p><i>The following conditions are written in French. If you need translations of these, please contact us: contact@gimli-med.io.</i></p>
            <h2>1 — Introduction</h2>
            <p>Gimli est une solution d'extraction, de structuration et d'analyse de données en santé. Notre but est d'aider la recherche médicale grâce aux nouvelles technologies d'intelligence artificielle. Notre équipe comprend des médecins et des ingénieurs afin de répondre au mieux aux besoins des chercheurs en santé. Notre souhait est de faciliter la recherche médicale tout en respectant les réglementations en vigueur.</p>
            <p>La sécurité des données de santé est un élément fondateur de notre démarche. Nous voulons faciliter le partage de ces données en toute sécurité dans le respect des droits du patient et du secret médical. Ces données ne seront utilisées que dans le cadre de la recherche médicale par des professionnels de santé, et ne seront transmises à aucun autre tiers. Si vous avez une quelconque question sur nos conditions générales, n'hésitez pas à envoyer un e-mail à <a href="mailto:dpo@gimli-med.io">dpo@gimli-med.io</a>.</p>
            <h2>2 - Définitions</h2>
            <p>Dans cette section, les termes commençant par une majuscule ont la signification suivante :</p>
            <ul>
                <li>CGU : désigne les présentes Conditions Générales d'Utilisation.</li>
                <li>Compte Gimli: désigne un compte permettant à un Utilisateur de se connecter à Gimli de manière authentifiée et sécurisée et d'accéder à un ensemble de fonctionnalités spécifiques à la nature de l'Utilisateur.</li>
                <li>Contenus et services : désigne l'ensemble des textes, images, fonctionnalités et plus largement tout élément existant au sein de Gimli.</li>
                <li>Documents médicaux :  désigne l'ensemble des documents d'ordre médical qui sont envoyés via la plateforme Gimli notamment, les comptes rendus, lettres, résultats d'analyse, etc.</li>
                <li>Données personnelles : désigne les données à caractère personnel, au sens de la Loi Informatique et Libertés et du RGPD.</li>
                <li>Données de santé : désigne les Données Personnelles relatives aux Patients, et notamment toute information concernant, par exemple, une maladie, un handicap, un risque de maladie, les antécédents médicaux, un traitement clinique ou l'état physiologique ou biomédical de la personne concernée.</li>
                <li>Loi Informatique et Libertés : désigne la loi n°78-17 du 6 janvier 1978 relative à l'Informatique, aux Fichiers et aux Libertés, modifiée par la loi n° 2004-801 du 6 août 2004, ainsi que toute autre réglementation applicable à la protection des données personnelles.</li>
                <li>Patient : désigne toute personne physique prise en charge par un Professionnel de Santé dans le cadre d'un parcours de soins auquel Gimli n'est pas partie.</li>
                <li>Plateforme : désigne tout logiciel, outil, ou programme informatique proposé aux Utilisateurs, accessible par Internet à l'adresse <a href="https://app.gimli-med.io/">https://app.gimli-med.io/</a> ou <a href="https://www.gimli-med.io">https://www.gimli-med.io/</a></li>
                <li>Professionnel de Santé :  désigne tout professionnel de santé exerçant au sein d'une structure libérale ou d'un établissement de santé, enregistré auprès de son ordre professionnel ou de son autorité d'enregistrement.</li>
                <li>RGPD : désigne le Règlement Général de Protection des Données de l'Union Européenne nᵒ 2016/679.</li>
                <li>Utilisateur : désigne un Professionnel de santé, un attaché de recherche clinique, ou un personnel médico-administratif qui utilise Gimli.</li>
            </ul>
            <h2>3 - Acceptation des CGUs et modifications</h2>
            <h3>3.1 Acceptation</h3>
            <p>
            Toute utilisation des services de Gimli est soumise au préalable à la prise de connaissance et à l'acceptation expresse et sans réserve des présentes Conditions Générales d'Utilisation et de fourniture des Services (ci-après «CGUs ») par les Utilisateurs. Les CGUs sont destinées à encadrer les conditions d'utilisation de Gimli, de ses Services et Contenus, par les Utilisateurs et de définir leurs droits et obligations.
            </p>
            <h3>3.2 Modification des CGUs</h3>
            <p>Pour l'évolution de ses services, Gimli se réserve le droit de modifier, à tout moment, les termes, conditions et mentions des présentes CGUs. Il est conseillé à l'Utilisateur de consulter régulièrement la dernière version des CGUs sur le site <a href="https://www.gimli-med.io/terms-of-use">gimli-med.io/terms-of-use</a>. Le fait de continuer à utiliser Gimli après toute modification des CGUs entraîne l'acceptation des modifications des CGUs. En cas de non-respect par l'Utilisateur des CGUs, Gimli se réserve le droit de suspendre ou de bloquer définitivement l'accès de l'Utilisateur au Compte Gimli, de plein droit et sans préavis.</p>
            <h2>4 - Description de Gimli</h2>
            <p>Gimli est une entreprise spécialisée dans la structuration de données médicales pour la recherche en santé, à partir de comptes rendus médicaux.</p>
            <h3>4.1 Chargement de documents dans le compte Gimli</h3>
            <p>La possibilité est donnée à tous les Utilisateurs de charger des données dans leur espace Utilisateur du compte Gimli. Ceux-ci peuvent être envoyés selon plusieurs modalités et plusieurs formats. Les documents envoyés sur la plateforme peuvent être anonymisés, pseudonymisés ou identifiés selon les besoins des utilisateurs qui sont responsables du cadre dans lequel ils utilisent leur compte Gimli ainsi que les données. Gimli met à disposition des Utilisateurs un système de pseudonymisation pour l'ensemble des documents si cela est nécessaire. Pour cela, il suffit de sélectionner l'option lorsque les documents sont chargés.</p>
            <h3>4.2 Extraction des données à partir de documents médicaux</h3>
            <p>Gimli permet l'identification, l'extraction et la structuration des données contenues dans les documents médicaux chargés. Une analyse préliminaire est réalisée, proposant un étiquetage des données, qui peut être changé secondairement en cas d'erreur directement par l'Utilisateur. Les données extraites sont standardisées dans des terminologies internationales (par exemple, SNOMED, RxNorm, CIM10, etc.). L'Utilisateur est seul responsable de l'étiquetage des données, et Gimli ne fait que réaliser des propositions.</p>
            <h3>4.3 Analyse de données et création de cohortes de patients</h3>
            <p>Gimli fourni des analyses statistiques basiques sur l'ensemble des documents chargés dans le compte Gimli. Ces données sont données à titre indicatif et peuvent contenir des erreurs en lien avec l'étiquetage préalable des données.</p>
            <p>Un outil de création de cohorte (<a href="http://app.gimli-med.io/cohorts">app.gimli-med.io/cohorts</a>) permet la sélection de patients ainsi que l'analyse de leur données. Les données pseudonymisées ou anonymisées de ces cohortes sont exportables selon plusieurs formats.</p>
            <h2>5 — Conditions d'accès et d'utilisation</h2>
            <p>Gimli se réserve le droit d'accorder l'accès à ses utilistaurL'Utilisateur reconnaît que l'accès et l'utilisation de Gimli pour la structuration de données et l'analyse de cohortes peut être un service payant. Les tarifs et les modalités de paiement des différents services proposés par Gimli seront discutés séparément. </p>
            <h3>Gratuité de la version</h3>
            <p>L'accès à Gimli pour essayer la solution de structuration de données est un service gratuit.</p>
            <h2>7 — Activation d'un compte Gimli</h2>
            <p>L'Utilisateur s'engage à utiliser Gimli dans le respect de ses obligations légales, réglementaires, et déontologiques, notamment à respecter les règles relatives au partage du secret médical. L'Utilisateur est seul responsable du contenu des messages et des documents chargé sur la plateforme Gimli et apprécie seul leur pertinence et leur sensibilité. Par ailleurs, l'Utilisateur s'engage à :</p>
            <ul>
                <li>ne pas gêner ou entraver l'utilisation par les autres Utilisateurs de Gimli, - protéger et garantir le maintien de la confidentialité de ses éléments d'authentification, étant précisé qu'il demeure pleinement responsable de toutes les activités qui se produisent sur son Compte Gimli, qu'il en ait ou non connaissance ;</li>
                <li>ne pas utiliser Gimli au bénéfice d'une action commerciale ou promotionnelle quelconque ;</li>
                <li>à ne pas diffuser du contenu illicite ou des informations contraires à l'ordre public et aux bonnes mœurs, et de manière générale à ne pas accomplir tout acte constitutif d'une infraction pénale. Gimli met à disposition des Utilisateurs une adresse e-mail support (support@gimli-med.io) dans le cas où l'Utilisateur souhaite signaler des contenus susceptibles de ne pas être conformes aux dispositions légales et règlementaires en vigueur.</li>
            </ul>
            <p>L'Utilisateur reconnaît expressément avoir été informé du fait que :</p>
            <ul>
                <li>son Compte Gimli est susceptible de contenir des données à caractère personnel sensibles relatives à la santé des Patients ; - les services et contenus fournis dans le cadre de Gimli ne peuvent être utilisés à d'autres fins que leur destination définie aux présentes CGUS ;</li>
                <li>il est soumis en tant que Professionnel de Santé à des règles professionnelles et déontologiques qu'il s'engage à respecter y compris dans le cadre de l'utilisation des services et sous sa responsabilité exclusive.</li>
                <li>Gimli n'est pas :
                    <ul>
                        <li>un outil de soin : ainsi, aucun acte médical, aucune diagnostic et prescription n'est réalisé à la suite de l'utilisation de Gimli ;</li>
                        <li>un dispositif médical ;</li>
                        <li>ne permet pas la gestion ni le suivi des situations d'urgence.</li>
                    </ul>
                </li>
            </ul>
            <p>L'Utilisateur met en oeuvre sous sa responsabilité exclusive l'ensemble des mesures de sécurité utiles et pertinentes pour les besoins de la protection des accès à son Compte Gimli et aux données qu'il contient.</p>
            <h2>8 — Limitations de responsabilité de Gimli</h2>
            <p>Gimli fait ses meilleurs efforts pour mettre à disposition un service de qualité à ses Utilisateurs. Toutefois, en cas de dysfonctionnement, Gimli ne pourra pas être tenu responsable :</p>
            <ul>
            <li>de dommages liés à l'impossibilité temporaire d'accéder à l'un des services proposés par le site internet <a href="app.gimli-med.io">app.gimli-med.io</a>. Le site est accessible de manière sécurisée via Internet et requiert la disponibilité d'un accès à Internet par tous moyens au choix, à la charge et aux de l'Utilisateur ;</li>
            <li>d'un mauvais traitement du service et de ses conséquences découlant d'un dysfonctionnement ou d'un accès restreint ou dégradé à Internet ;</li>
            <li>de tout dommage ni des conséquences résultant de son utilisation de Gimli, de ses services et contenus ;</li>
            <li>de l'impossibilité d'avoir accès à Gimli, aux Services et Contenus, à l'exception des dommages directs consécutifs à une faute lourde ou intentionnelle ;</li>
            <li>de l'inaccessibilité de <a href="https://www.gimli-med.io">gimli-med.io</a> et de ses contenus et services causée par des cas de force majeure. L'exécution des présentes CGUs pourra être suspendue tant que le cas de force majeure durera ;</li>
            <li>des contenus et des sites qui peuvent être liés à gimli-med.io et n'est en aucun cas responsable du contenu des sites vers lesquels des liens sont faits.</li>
            </ul>
            <p>L'Utilisateur reconnaît que Gimli n'est tenu qu'à une obligation de moyens et n'est pas tenu à une obligation générale de surveillance des contributions. Les Utilisateurs reconnaissent que le bon fonctionnement de Gimli est soumis aux conditions d'accès au réseau Internet et à ses limites et restrictions éventuelles (couverture, saturation, disponibilité). En toutes hypothèses, tout Utilisateur bénéficiant du service Gimli demeure intégralement et exclusivement responsable des actes et des décisions relevant de son activité professionnelle, conformément aux obligations légales, réglementaires et déontologiques présidant à son exercice professionnel. D'une manière générale, la sécurité du Compte Gimli impose aux Utilisateurs de respecter les<a href="https://esante.gouv.fr/produits-services/pgssi-s/corpus-documentaire"> </a><a href="https://esante.gouv.fr/produits-services/pgssi-s/corpus-documentaire">mesures de sécurité définies par l'ASIP Santé</a> et notamment les règles relatives à la définition et au changement des éléments d'authentification.</p>
            <h2>9 — Protection des données à caractère personnel</h2>
            <h3>9.1 Données de santé à caractère personnel</h3>
            <p>L'Utilisateur Professionnel de Santé, médecin libéral ou établissement de santé, qui utilise Gimli est le responsable de traitement des données de santé à caractère personnel transitant sur la plateforme de Gimli. Il revient donc aux Utilisateurs Professionnels de santé qui utilisent Gimli de respecter les dispositions du <a href="https://www.cnil.fr/sites/default/files/atoms/files/guide-cnom-cnil.pdf">guide pratique</a> rédigé conjointement par la CNIL et le CNOM qui expose l'aide à la conformité des professionnels de santé et de mettre en œuvre les mesures permettant de garantir la confidentialité, la sécurité et l'intégrité des données de santé à caractère personnel. Gimli n'agit au regard des données personnelles des Patients qu'en qualité de sous-traitant de l'Utilisateur responsable de traitement et selon ses instructions. Gimli est responsable de traitement au regard des données personnelles de l'Utilisateur Professionnel de Santé. Gimli invite expressément l'Utilisateur à prendre connaissance de sa <a href="https://www.gimli-med.io/privacy-policy">Politique relative à la protection des Données Personnelles</a> qui fait partie intégrante des présentes CGUs, au regard des droits des Patients et Professionnels de Santé sur leurs données personnelles.</p>
            <h3>9.2 Cookie et adresse IP</h3>
            <p>Gimli invite expressément l'Utilisateur à prendre connaissance de sa<a href="https://www.gimli-med.io/data-privacy"> Politique relative à la protection des Données Personnelles</a> qui fait partie intégrante des présentes CGUs en matière de cookies.</p>
            <h2>10 — Propriété intellectuelle</h2>
            <p>L'Utilisateur reconnaît que l'ensemble des contenus figurant sur gimli-med.io incluant de manière non limitative : le nom de domaine, marques, les textes, les graphiques, les photographies, les dessins, les sons, données, images, audio et vidéo, mais également l'arborescence de Gimli, son plan de navigation, ses logos, la conception et l'organisation de ses rubriques, leurs titres, les bases de données, leur structure et leurs contenus, existants ou à venir, sont protégés et sont la propriété exclusive de Gimli. En conséquence, sauf autorisation expresse et préalable de Gimli, toutes reproductions, représentations et utilisations par l'Utilisateur autres que celles visées ci-dessus sont interdites et notamment :</p>
            <p>(i) toute adaptation, mise à disposition du public à sa demande ou non, distribution, rediffusion sous quelque forme que ce soit, mise en réseau, communication publique, à titre gratuit ou onéreux, de tout ou partie des œuvres, prestations, et tous éléments protégés ou susceptibles de protection par le droit de la propriété intellectuelle reproduits au sein de Gimli ;</p>
            <p>(ii) tout lien, accès, modification, ajout, suppression qui porte sur le système de traitement automatisé de l'édition en ligne et qui modifie les conditions de publication ou la politique éditoriale. Tout manquement de l'Utilisateur à ces obligations constituerait une contrefaçon au sens des articles L. 335-2 et suivants du Code de la propriété intellectuelle.</p>
            <h2>11 — Résiliation</h2>
            <p>Chaque Utilisateur accepte que Gimli suspende l'accès de l'Utilisateur sans préavis ni mise en demeure ni indemnité de quelque nature que ce soit, en cas de manquement à l'une quelconque des obligations décrites aux présentes CGUs, ou à la législation en vigueur et notamment :</p>
            <ul>
                <li>les infractions ou violations des CGUs ;</li>
                <li>le non-respect d'une disposition légale ou réglementaire en vigueur ;</li>
                <li>une tentative de connexion non autorisée, par une utilisation frauduleuse du système ou par une usurpation de codes d'accès ;</li>
                <li>un dysfonctionnement technique ayant conduit par erreur à la désactivation de l'inscription de l'Utilisateur. Chaque Utilisateur peut à tout moment résilier son Compte Gimli en contactant Gimli à l'adresse e-mail suivante: support@gimli-med.io.</li>
            </ul>
            <h2>12 — Nullité ou invalidité partielle</h2>
            <p>Si l'une des dispositions des CGUs était jugée invalide par un tribunal compétent, l'invalidité de cette disposition n'en affecterait pas pour autant la validité des autres dispositions des présentes.</p>
            <h2>13 — Droit applicable</h2>
            <p>Gimli a été conçue pour des Utilisateurs Professionnels de Santé français. Les CGUs sont soumises au droit français. À défaut de règlement amiable, tout litige relatif à Gimli ou en relation avec son utilisation sera soumis aux tribunaux français.</p>
        </div>
    );
}

export default TermsOfUse