import React from 'react';
import './legalesMentions.css'

const PrivacyPolicy = () => {

    return (
        <div className='app'>
        <h1>Politique de confidentialité/Privacy policy</h1>
        <p><i>The following conditions are written in French. If you need translations of these, please contact us: contact@gimli-med.io.</i></p>
        <h2>1 - Introduction</h2>
        
        <p>GIMLI SAS sera désigné par &ldquo;Gimli&rdquo; pour les besoins de la suivante.</p>
        <p>
            
                Gimli accorde une grande importance à la protection de votre vie privée et de vos données personnelles. C'est un élément fondateur de notre démarche. Cette charte de protection
                des données personnelles (ci-après la &laquo;
            
            Charte
            
                 &raquo;), a donc pour objet de vous présenter plus en détail notre approche face à vos données personnelles, de vous expliquer les cas dans lesquels nous les collectons, les raisons
                justifiant cette collecte et ce que nous en faisons. Elle présente aussi les mesures de sécurité que nous appliquons pour protéger leur confidentialité, rappelle vos droits sur vos
                données personnelles, et les moyens pour les exercer. De manière générale, votre attention est attirée sur le fait que les données collectées via Gimli sont des données
                sensibles et confidentielles qui nécessitent une vigilance particulière.
            
        </p>
        
        <p>
            
                Ces données désignent les informations qui concernent des personnes physiques, identifiées ou identifiables, directement ou indirectement, y compris les données relatives à la santé
                physique ou mentale (ci-après les &laquo;
            
            Données personnelles
            
                 &raquo;). Notre Charte s'applique dans le respect des dispositions relatives à la protection des Données personnelles, et notamment, le Règlement européen 2016/679 du 27 avril 2016, et
                la loi Informatique et Liberté de 1978 modifiée, ainsi que les dispositions du
            
            Code de la santé publique (CSP), (ci-après la &laquo; Loi
             &raquo;). Cette Charte a vocation à s'appliquer aux utilisateurs de Gimli et aux visiteurs lors de leur navigation sur le site{" "}
            <a href="http://www.gimli-med.io">gimli-med.io</a>
             (ci-après le &laquo; Site &raquo;). La Charte fait partie intégrante des{" "}
                <a href="https://gimli-med.io/terms-of-use">
                    Conditions Générales d'Utilisation du Site
                </a>
            
            .
        </p>
        <h2>2 - Définitions</h2>
        <h2></h2>
        <p>Pour les besoins des présentes, les termes et expressions définis ci-après, ont la signification suivante :</p>
        <ul>
            <li>
                &ldquo;Autorité de contr&ocirc;le
                &rdquo; désigne la Commission Nationale de l'Informatique et des Libertés (CNIL) ou toute autre autorité de contr&ocirc;le compétente ;
            </li>
            <li>
                &ldquo;Compte Gimli
                &rdquo; désigne un compte permettant à un utilisateur de se connecter à Gimli de manière authentifiée et sécurisée ;
            </li>
            <li>
                &ldquo;DPO
                &rdquo; désigne le Délégué à la protection des données à caractère personnel, conformément à l'article 37 du RGPD ;
            </li>
            <li>
                &ldquo;Données à caractère personnel
                
                    &rdquo; désigne les informations qui concernent des personnes physiques, identifiées ou identifiables, directement ou indirectement, dont l'Utilisateur est le Responsable du traitement, et qui sont
                    traitées par Gimli pour le compte du Client ;
                
            </li>
            <li>
                &ldquo;Données de santé à caractère personnel
                
                    &rdquo; désigne les Données à caractère personnel, relatives à la santé physique ou mentale d'une personne physique, comprenant notamment les données produites
                    à l'occasion des activités de prévention, de diagnostic, de soin ou de dispensation de médicaments d'un Professionnel de santé et tout élément de nature
                    à caractériser la santé d'une personne physique ;
                
            </li>
            <li>
                &ldquo;Patient&rdquo; désigne les personnes concernées par les documents médicaux, les questionnaires médicaux ;
            </li>
            <li>
                &ldquo;Loi
                &rdquo; désigne toutes les lois et réglementations applicables aux traitements des Données à caractère personnel ;
            </li>
            <li>
                &ldquo;Professionnel de santé
                
                    &rdquo; désigne tout professionnel de santé exer&ccedil;ant au sein d'une structure libérale ou d'un établissement de santé, enregistré auprès de son ordre
                    professionnel ou de son autorité d'enregistrement ;
                
            </li>
            <li>
                &ldquo;Responsable du traitement
                
                    &rdquo; désigne la personne physique ou morale, qui, seule ou conjointement avec d'autres, détermine les finalités et les moyens des traitements des données à caractère
                    personnel ;
                
            </li>
            <li>
                &ldquo;Sous-traitant
                &rdquo; désigne la personne physique ou morale, qui traite des Données à caractère personnel pour le compte du Responsable du traitement ;
            </li>
            <li>
                &ldquo;Sous-traitant de second rang
                
                    &rdquo; désigne un Sous-traitant tiers mandaté par Gimli qui, dans le cadre de la prestation de services pour le compte de Gimli, est autorisé à traiter des Données à
                    caractère personnel ;
                
            </li>
            <li>
                &ldquo;Traitements
                
                    &rdquo; désigne toute opération ou ensemble d'opérations, effectuées ou non à l'aide de procédés automatisés, et appliquées à des
                    Données ou des ensembles de Données à caractère personnel ;
                
            </li>
            <li>
                &ldquo;Utilisateur
                
                    &rdquo; désigne un professionnel de santé (exer&ccedil;ant au sein d'une structure libérale ou d'un établissement de santé, enregistré auprès de son ordre
                    professionnel ou de son autorité d'enregistrement, et les structures, services ou PMSI), une secrétaire médicale, un assistant médico-administratif qui utilise Gimli.
                
            </li>
        </ul>
        
        
        <h2>3 - Qui traite vos données personnelles ?</h2>
        <p>Le responsable de la collecte et du traitement de vos Données personnelles est :</p>
        <ul>
            <li>
                L'Utilisateur
                
                    , pour les Données personnelles de santé collectées : Nous agissons comme sous-traitant. Cela signifie que Gimli, en sa qualité de sous-traitant, ne traite ces Données personnelles que
                    sur instruction des Utilisateurs. En tant que responsable des traitements, l'Utilisateur effectue les formalités déclaratives de conformité auprès de la CNIL pour utiliser Gimli. Si
                    Gimli n'est pas tenue de faire ces formalités déclaratives, nous avons néanmoins mis en place les mesures de sécurité et de confidentialité propres à garantir la
                    protection de vos Données personnelles.
                
            </li>
        </ul>
        
        <ul>
            <li>
                Gimli,
                
                     y compris toute société contr&ocirc;lée par elle aux termes de l'article L.233-1 du Code de commerce, pour les Données Personnelles collectées, soit dans le cadre de la
                    création du Compte Gimli de l'Utilisateur, soit à partir des formulaires de contact, soit lors de votre navigation sur gimli-med.io, soit pour les données personnelles que les patients
                    utilisateurs transmettent via leur compte personnel à un professionnel de santé.
                
            </li>
        </ul>
        <p>
            
                Nous sous-traitons certaines de nos activités pour la réalisation de nos services. Ainsi, l'hébergement des Données personnelles traitées est effectué auprès
                d'hébergeurs certifiés Hébergeurs de Données de Santé par le Ministre de la Santé, la société AWS.
            
        </p>
        <p>
            Nous nous engageons à ce que nos sous-traitants garantissent le même niveau de sécurité que le n&ocirc;tre. L'Utilisateur et Gimli (ci-après les &laquo; 
            Parties
            
                 &raquo;) s'engagent à respecter la Loi applicable aux Traitements de Données à caractère personnel. Les Parties s'engagent à se conformer à la Politique
                Générale de Sécurité des Systèmes d'Information de Santé émise par l'ASIP Santé (ci-après la &quot;
            
            PGSSI-S&quot;).
        </p>
        
        <h2>4 - à quelles fins vos données sont-elles traitées ?</h2>
        
        <p>Gimli traite les Données personnelles :</p>
        <ul>
            <li>des patients dans le cadre de la recherche clinique en santé;</li>
            <li>des utilisateurs pour améliorer l'expérience sur le site gimli-med.io et l'application app.gimli-med.io ;</li>
        </ul>
        <p>
            
                Pour la recherche clinique, le traitement effectué consiste à extraire des informations contenues dans les documents médicaux, puis les utiliser pour accomplir les finalités du traitement.
            
        </p>
        
        <p>
            
                Sauf avis contraire expressément donné par l'Utilisateur, Gimli n'utilise pas vos Données personnelles à des fins de démarchage commercial ou marketing, de recherche non
                médicale, de publication de statistiques.
            
        </p>
        
        <h2>5 - Quelles sont les données traitées ?</h2>
        <p>Les Données personnelles que nous traitons sont :</p>
        <ul>
            <li>Des données personnelles de contact (nom, prénom, date de naissance, email, numéro de mobile, adresse postale) ;&zwj;</li>
            <li>
                Des données personnelles à caractère sensible (données de santé) que sont par exemple, les comptes rendus médicaux, les questionnaires médicaux, etc ;
                &zwj;
            </li>
            <li>Des données personnelles de tra&ccedil;abilité et de navigation sur le site (adresse IP, cookies, logs&hellip;).</li>
        </ul>
        
        <p>Cookies</p>
        
        <p>De quoi s&#39;agit-il ?</p>
        
        <p>Un &laquo; cookie &raquo; est une information stockée sur votre appareil quand vous naviguez sur un site internet. Il permet d'identifier votre appareil à chaque visite.</p>
        
        <p>à quoi servent-ils ?</p>
        
        <p>Nous utilisons des cookies afin de :</p>
        <p>- Vous offrir une meilleure expérience de navigation sur le gimli-med.io ;</p>
        <p>- Mesurer et améliorer les services proposés sur gimli-med.io.</p>
        
        <p>Que pouvez-vous faire pour gérer les cookies stockés sur votre appareil ?</p>
        
        <p>
            
                Vous pouvez accepter ou refuser les cookies. Si vous refusez les cookies, certains aspects de notre Site peuvent ne pas fonctionner sur votre appareil et vous pourriez ne pas pouvoir accéder à certaines
                fonctionnalités de notre Site.
            
        </p>
        
        <h2 id="h.kk0ok3bzntky">6 - Que faisons-nous de vos données ?</h2>
        <p>
            
                Les Données personnelles de santé sont strictement destinées aux Utilisateurs concernés qui les transmettent. Gimli garantit qu'elles ne seront transmises à aucun tiers non
                autorisé, sous réserve des sous-traitants éventuels de Gimli, tel que l'hébergeur de données de santé certifié.
            
        </p>
        
        <p>
            
                Les Données personnelles collectées dans les formulaires de contact, et les cookies, sont uniquement destinées aux administrateurs de Gimli. Nous ne procédons à aucun transfert de
                Données personnelles vers les pays qui ne sont membres, ni de l'Union Européenne ni de l'Espace économique Européen.
            
        </p>
        
        <p>
            
                Gimli pourrait cependant être amenée à communiquer à des tiers les Données personnelles qu'elle traite lorsqu'une telle communication est requise par la loi, une disposition
                réglementaire ou une décision judiciaire, ou si cette communication est nécessaire pour assurer la protection et la défense de ses droits.
            
        </p>
        
        <p>Concernant les Données de santé à caractère personnel, Gimli s'engage, lorsque le cadre légal le permet :</p>
        <ul>
            <li>en priorité à ne communiquer les données demandées par l'autorité demandeuse que sur accord du Responsable de traitement ;</li>
            <li>
                
                    à communiquer dans les meilleurs délais au Responsable de traitement tout élément d'information sur la portée des saisies et/ou sur les personnes concernées par les
                    données saisies.
                
            </li>
        </ul>
        <p>L'Utilisateur, en tant que Responsable de Traitement de Données de santé à caractère personnel, est responsable de :</p>
        <ul>
            <li>Superviser les Traitements ;</li>
            <li>
                Avoir procédé aux formalités déclaratives auprès de la CNIL nécessaires aux Traitements auxquels il procède via l'utilisation de Gimli.
            </li>
        </ul>
        <p>Gimli, en tant que Sous-traitant, s'engage à :</p>
        <ul>
            <li>Procéder aux Traitements qui lui sont confiés, uniquement dans le cadre des finalités faisant l'objet des Traitements ;</li>
            <li>Tenir un registre des activités de Traitements ;</li>
            <li>
                Mettre à la disposition du Responsable du traitement, toutes les informations nécessaires pour démontrer le respect des obligations à sa charge ;
            </li>
            <li>
                
                    Communiquer l'extrait de son registre des activités de Traitements correspondant au traitement relevant de la responsabilité du Responsable du traitement, sur demande écrite de ce dernier ;
                
            </li>
            <li>
                
                    Faire respecter le secret professionnel le plus absolu concernant les Données de santé à caractère personnel qui transitent par Gimli aux personnes placées sous sa responsabilité
                    ou aux prestataires susceptibles d'intervenir dans l'exécution. Gimli est tenu, en application du RGPD, d'informer le Responsable de Traitement si une de ses instructions constitue une violation
                    de la Loi.
                
            </li>
        </ul>
        <h3 id="h.xe3iibo4szu1"></h3>
        <h3 id="h.b72r6uhr4v"></h3>
        <h3 id="h.xwtuhefx4e68">Sous-traitant de second rang</h3>
        <p>
            
                Conformément à l'article 28 du RGPD, le Responsable du traitement autorise de manière générale Gimli à avoir recours à des sous-traitants (ci-après &ldquo;
            
            Sous-traitant de second rang&rdquo;), pour mener des activités de traitement spécifiques.
        </p>
        
        <p>
            
                Gimli s'assure que le Sous-traitant de second rang présente les mêmes garanties quant à la mise en oeuvre des Mesures de sécurité pour les missions qui lui ont été
                confiées. Gimli s'engage à conclure un contrat avec le Sous-traitant de second rang aux termes duquel son accès aux données du Responsable du traitement sera strictement limité
                à l'objet du contrat conclu avec Gimli.
            
        </p>
        
        <p>
            
                Les Sous-traitants de second rang avec lesquels Gimli a conclu un contrat, en cours à la date de signature du Contrat, sont AWS (hébergeur certifié), OVH (hébergeur certifié) Pro
                Santé Connect (connection sécurisée en santé).
            
        </p>
        
        <p>Le Responsable du traitement a le droit de s'opposer au recours à un Sous-traitant de second rang à compter de la date de réception de cette information.</p>
        
        <h2 id="h.n9stkaq9evhe">7 - Protection de vos données et durée de conservation</h2>
        <h3 id="h.hcci98yzxxb9">Sécurité</h3>
        <p>
            
                Nous mettons en &oelig;uvre toutes les mesures de sécurité requises pour protéger vos Données personnelles. Pour garantir la sécurité de vos Données personnelles, Gimli a mis
                en place les procédures et processus suivants :
            
        </p>
        <ul>
            <li>La pseudonymisation et / ou le chiffrement des connexions et des Données personnelles ;</li>
            <li>
                La capacité de garantir la confidentialité, l'intégrité, la disponibilité et la résilience permanentes des systèmes et services de Traitements ;
            </li>
            <li>
                
                    La possibilité de rétablir la disponibilité et l'accès aux Données à caractère personnel dans des délais appropriés, en cas d'incident physique
                    ou technique ;
                
            </li>
            <li>L'hébergement des Données personnelles de santé auprès d'hébergeurs de données de santé certifiés ;</li>
            <li>L'authentification forte des Utilisateurs pour accéder aux Données personnelles ;</li>
            <li>
                
                    Les mesures appropriées pour protéger les Données personnelles contre toute perte, utilisation détournée, accès non autorisé, divulgation, altération ou destruction
                    ;
                
            </li>
            <li>La tra&ccedil;abilité des accès ;</li>
            <li>
                
                    Un processus pour tester, analyser et évaluer régulièrement l'efficacité des mesures techniques et organisationnelles, pour assurer la sécurité des Traitements ;
                
            </li>
        </ul>
        <p>
            
                Les Parties s'engagent à prendre les mesures appropriées afin de garantir que tout salarié, partenaire, sous-traitant et toute personne physique agissant sous l'autorité du Responsable
                du traitement ou sous celle de Gimli, est d&ucirc;ment habilité à accéder aux Données à caractère personnel. Les professionnels de santé qui utilisent Gimli sont soumis au
                secret professionnel par la Loi. Ainsi, chaque Utilisateur est invite&#769; a&#768; mettre en &oelig;uvre, sous sa responsabilité, l'ensemble des mesures de sécurité utiles et pertinentes pour les
                besoins de la protection des accès à son ordinateur ou son équipement portable ou mobile, et a&#768; l'ensemble des Données personnelles accessibles sur Gimli, en particulier vis-
                a&#768;-vis des tiers.
            
        </p>
        
        <p>
            
                Afin de garantir la confidentialité, l'intégrité et la sécurité des Données à caractère personnel, Gimli reconna&icirc;t avoir mis en &oelig;uvre les Mesures de
                sécurité ci-dessous, destinées à protéger les Données à caractère personnel :
            
        </p>
        <ul>
            <li>
                
                    Toutes les Données à caractère personnel traitées par Gimli sont hébergées, c'est-à-dire stockées de fa&ccedil;on durable ou ponctuelle pour les besoins des
                    traitements, auprès d'un hébergeur de données de santé certifié par le Ministre de la Santé ;
                
            </li>
            <li>
                
                    L'ensemble des Données à caractère personnel traitées par Gimli sont sauvegardées sur un système assurant leur redondance et dont l'hébergement est
                    assuré par un hébergeur de données de santé certifié par le Ministre de la Santé sur les mêmes lieux d'hébergement ;
                
            </li>
            <li>
                
                    L'ensemble des connexions au serveur sont chiffrées selon le protocole HTTPS. L'ensemble des Données de santé à caractère personnel sont stockées et chiffrées
                    dans une base de données &ldquo;données de santé&rdquo; ;
                
            </li>
            <li>
                
                    Conformément aux dispositions de l'article L.1110-4 du Code de la santé publique, au décret n&deg;2007-960 du 15 mai 2007 dit &laquo; décret confidentialité &raquo; et aux
                    recommandations de l'ASIP Santé, notamment le &laquo; Référentiel d'authentification des acteurs de santé &raquo;, Gimli a mis en place un système d'authentification
                    forte des Utilisateurs ;
                
            </li>
            <li>
                
                    Les accès aux traces fonctionnelles et aux Données à caractère personnel font l'objet d'une tra&ccedil;abilité et d'une journalisation conformément aux
                    principes de la PGSSI-S. Gimli s'engage, sur demande, à donner accès cette tra&ccedil;abilité au Responsable de traitement ;
                
            </li>
            <li>
                Les comptes rendus médicaux transmis par voie postale sont envoyés au Prestataire d'éditique, par un serveur FTP sécurisé avec restriction d'IP ;
            </li>
        </ul>
        <h3>Zones géographiques</h3>
        <p>Gimli héberge les données à caractère personnel chez des hébergeurs, qui peuvent être indifféremment  :</p>
        <ul>
            <li>
                
                    La société OVH SAS, société par actions simplifiées, immatriculée au registre du commerce et des sociétés de Lille sous le numéro &#8203;&#8203;B 424761419,
                    dont le siège social est situé
                
                2, rue Kellermann, 59100, Roubaix. 
            </li>
            <li>
                
                    La société Amazon Web Services EMEA SARL, société à responsabilité limitée immatriculée au registre du commerce et des sociétés de Luxembourg sous le
                    numéro B186284, dont le siège social est situé au 38, avenue John F. Kennedy, L &ndash; 1855 Luxembourg, dont les espaces de stockage sont situés à Paris. Dans ce cadre les
                    Données à caractère personnel transitent sur les serveurs du site AWS de Dublin sans y être stockées.<br />
                
            </li>
        </ul>
        <p>
            
                Si Gimli vient à contracter avec un nouvel hébergeur hors France, il s'engage à donner le choix au Responsable de Traitement quant au pays d'hébergement de ses Données à
                caractère personnel.
            
        </p>
        
        <h3>Durées de conservation</h3>
        <h4>Données de santé</h4>
        
        <p>
            Gimli s'engage à conserver les Données à caractère personnel collectées pendant une durée de conservation limitée. Gimli n'est en revanche pas garant des
                obligations incombant au Responsable de Traitement en matière de durée de conservation des Données à caractère personnel.
            
        </p>
        
        <p>
            
                Les durées de conservation des Données à caractère personnel collectées via Gimli sont différentes selon les types de données et sont précisées ci-dessous :
            
        </p>
        
        <ul>
            <li>Comptes-rendus médicaux (PDF, word, notes) : 1 an (modifiable)</li>
            <li>Informations patients (Nom, Prénom, coordonées) : 1 an (modifiable)</li>
            <li>Extractions de textes : 1 mois (modifiable)</li>
            <li>Coordonnées de médecins : 1 an (modifiable)</li>
            <li>Résultats d'analyses pseudonymisés : 1 an (modifiable)</li>
        </ul>
        
        <p>à l'issue des délais de conservation, Gimli s'engage, au choix du Responsable du traitement, à :</p>
        <ul>
            <li>Détruire les Données à caractère personnel ;</li>
            <li>Restituer les Données à caractère personnel au Responsable du traitement ;</li>
            <li>Archiver les données à caractère personnel après leur anonymisation.</li>
        </ul>
        <p>L'Utilisateur peut demander la suppression de l'ensemble de l'ensemble des Données de santé à caractère personnel à tout moment. Ces données seront alors supprimées dans les 7 jours.</p>
        <p>De même, l'Utilisateur peut demander la suppression de son compte Gimli. Cela entrainera une suppression de ses Données à caractère personnel et des Données de santé à caractère personnel lié à son compte dans les 7 jours.</p>
        <h4>Données administratives</h4>
        <p>
            Les Données personnelles utilisées à des fins d'envoi de formulaire de contact et de gestion de fichiers client de Gimli, sont conservées pendant une durée de trois ans à
            compter de leur collecte ou du dernier contact avec Gimli.
        </p>
        
        <h4>Données de navigation</h4>
        
        <p>
            Les logs de connexion, cookies et autres traceurs mis en place sur notre Site, seront conservés conformément à la réglementation applicable pour une durée de 13 mois. Pour plus de
            détails, voir la rubrique cookies ci-dessus.
        </p>
    
        <h2>8 - Quels sont vos droits et comment les exercer ?</h2>
        <p>
            Conformément à la Loi, vous disposez d'un droit d'accès, de rectification, de limitation, d'opposition, d'effacement et de portabilité sur vos Données personnelles,
                que vous pourrez exercer pour motifs légitimes, et sous réserve des motifs légitimes impérieux dont pourrait justifier Gimli pour conserver vos Données personnelles. Ces droits peuvent
                être exercés à tout moment en envoyant un courriel explicatif de votre demande à l'adresse contact@gimli-med.io ou en envoyant votre demande par courrier postal, à l'adresse :
            
            Gimli SAS, 231 rue Saint-Honoré, 75001, Paris.
        </p>
        <p>
            En cas de demande, Gimli s'engage à informer, dans les meilleurs délais, le Responsable du traitement et à lui fournir les informations nécessaires pour la transmission des données
            à son patient.
        </p>
        
        <p>
            
                Le Responsable de traitement reconna&icirc;t, de manière générale, être exclusivement responsable de l'information préalable dans le respect des dispositions du RGPD.
                L'Utilisateur, en tant que Responsable de Traitement, doit désigner une personne dans son organisation (le &laquo;
            
            Contact Client
            
                 &raquo;) qui sera en mesure de désigner à Gimli un professionnel de santé lorsque cela s'avèrera nécessaire, par exemple pour tout problème nécessitant
                l'accès aux données de santé ou relatif à la gestion des relations avec le patient. Le Responsable de Traitement doit s'assurer de communiquer à Gimli, par le biais de son DPO,
                un nouveau Contact Client lorsque cela s'avèrera nécessaire, notamment en cas de départ de celui-ci.
            
        </p>
        
        <h3 id="h.7pqhwxoezste">Droit d'information</h3>
        <p>Nous vous informons de la collecte et du traitement de vos Données personnelles et des droits dont vous disposez à ce titre :</p>
        <ul>
            <li>
                dans la présente Charte dont les Utilisateurs peuvent prendre connaissance à tout moment sur notre Site ou lors de la création d'un Compte Gimli ;
            </li>
            <li>au sein des CGU ;</li>
            <li>lors de la création d'un Compte Gimli.</li>
        </ul>
        <p>
            
                Nous informons nos Utilisateurs faisant partie d'une même équipe de soins, qu'il leur appartient de délivrer aux patients qu'ils prennent en charge (ci-après les &laquo; Patients
                &raquo;), préalablement au partage de leurs données de santé, les informations suivantes :
            
        </p>
        <ul>
            <li>leur identité en qualité de responsable de traitement ;</li>
            <li>
                la finalité du service de messagerie sécurisée et ses conditions de mise en &oelig;uvre y compris en cas d'hébergement des données auprès d'un hébergeur
                certifié à cet effet ;
            </li>
            <li>les données collectées ;</li>
            <li>les destinataires des données ;</li>
            <li>la durée de conservation des données ;</li>
            <li>les droits dont ils disposent et les modalités d'exercice de ces droits ;</li>
        </ul>
        <p>Pour les Utilisateurs de Gimli n'appartenant pas à une même équipe de soins, nous vous informons que le Patient doit expressément consentir :</p>
        <ul>
            <li>à la collecte et au traitement de Données de santé ;</li>
            <li>
                au partage d'informations le concernant explicitement. La délivrance d'une information, et le recueil du consentement du Patient doivent être effectués par l'Utilisateur, par la
                communication d'une notice d'information-consentement.
            </li>
        </ul>
        <h3>Droits d'accès, de rectification et de limitation :</h3>
        <p>Vous pouvez nous demander à tout moment :</p>
        <ul>
            <li>des précisions sur vos Données personnelles que nous traitons ;</li>
            <li>de modifier vos Données personnelles ;</li>
            <li>de limiter les traitements de vos Données personnelles.</li>
        </ul>
        <h3>Droit à la portabilité</h3>
        <p>
            
                Vous avez le droit de récupérer les Données personnelles que vous nous avez fournies. Gimli s'engage à vous fournir vos Données personnelles dans un format structuré,
                couramment utilise&#769; et lisible.
            
        </p>
        <h3>Droits d'opposition et droit à l'effacement</h3>
        <p>Vous pouvez vous opposer au traitement ou demander l'effacement de vos Données personnelles, c'est-à-dire leur suppression par Gimli.</p>
        <h2>9 - à qui adresser vos demandes ?</h2>
        <p>
            Nous avons désigné un Délégué à la Protection des Données personnelles (ci-après le &ldquo;DPO&rdquo;
            
                ), pour témoigner de notre engagement en faveur du respect de votre vie privée et de vos droits sur vos Données personnelles. Pour toute question en lien avec le traitement des Données
                personnelles par Gimli, vous pouvez contacter notre DPO aux coordonnées suivantes : dpo@gimli-med.io, 231 rue Saint-Honoré, 75001, Paris.
            
        </p>
        <h2>10 - Autres engagements</h2>
        <h3>Analyse d'impact relative à la protection des Données à caractère personnel</h3>
        
        <p>
            Conformément à l'article 35 du RGPD, le Responsable du traitement s'engage à réaliser une analyse d'impact pour s'assurer de la conformité des Traitements à
            la Loi, lorsqu'ils sont susceptibles d'engendrer un risque élevé pour les droits et libertés des personnes concernées par les Traitements. 
        </p>
        
        <p>
            
                Dans l'hypothèse o&ugrave; Gimli aurait connaissance d'un risque élevé pour les droits et libertés des personnes concernées par les Traitements, nous nous engageons à
                informer, dans les meilleurs délais, le Responsable du traitement d'un tel risque, et à lui apporter son aide pour la réalisation de l'analyse d'impact, ainsi que pour la
                réalisation de la consultation préalable de l'autorité de contr&ocirc;le.
            
        </p>
        
        <h3>Certification</h3>
        
        <p>Nous nous engageons à fournir sur demande du Responsable de Traitement les preuves de nos certifications, et à l'informer de tout changement de bureau de certification dans un délai de 30 jours.</p>
        
        <p>
            
                Par ailleurs nous nous engageons à fournir sur demande du Responsable de Traitement le dernier rapport d'audit relatif à nos certifications. A ce jour nous n'avons pas encore réalisé
                d'audit de certification.
            
        </p>
        
        <h3>Notification des violations de Données à caractère personnel</h3>
        
        <p>
            
                Conformément à l'article 33 du RGPD, le Responsable du traitement s'engage à notifier à l'autorité de contr&ocirc;le, dans un délai maximum de soixante-douze (72)
                heures à compter de sa prise de connaissance, toute violation de Données à caractère personnel.
            
        </p>
        
        <p>
            
                Gimli s'engage à informer le Responsable du traitement, dans les meilleurs délais après en avoir pris connaissance, de toute violation de Données à caractère personnel, portant
                sur les Traitements relevant de la responsabilité du Responsable de traitement, et à prendre les mesures appropriées pour limiter le risque et protéger les Données à caractère
                personnel.
            
        </p>
        
        <p>
            
                La notification sera adressée par Gimli au Responsable du traitement par courriel, et contiendra, autant que possible, tout élément d'information utile au Responsable du traitement afin de lui
                permettre de notifier, si nécessaire, la violation à l'autorité de contr&ocirc;le.
            
        </p>
        
        <p>La notification adressée au Responsable du traitement par Gimli, ne constitue pas une reconnaissance de faute ou de responsabilité de ce dernier.</p>
        
        <h3>Audit</h3>
        
        <p>
            Le Responsable du traitement se réserve la possibilité de réaliser des audits pour vérifier la conformité de Gimli aux dispositions de la Charte.
        </p>
        
        <p>
            
                Après en avoir informé Gimli par écrit, y compris par courriel à l'adresse dpo@gimli-med.io, en respectant un préavis de vingt (20) jours, le Responsable du traitement peut faire
                réaliser, à ses frais, un audit du respect de l'ensemble des dispositifs de sécurité mis en &oelig;uvre pour assurer la sécurité des Données à caractère
                personnel. Un tel audit peut avoir lieu à tout moment, dans la limite d'un audit par année civile.
            
        </p>
        
        <p>
            
                L'audit devra être réalisé par un expert indépendant et reconnu, dont le choix devra être validé par Gimli au minimum cinq (5) jours avant le début de l'audit. Un
                tel audit fera l'objet d'une convention tripartite dont les principales clauses seront conformes au référentiel d'exigences PASSI publié par l'ANSSI.
            
        </p>
        
        <p>
            
                En tout état de cause, les opérations d'audit ne devront pas perturber le fonctionnement du service mis en &oelig;uvre par Gimli au-delà des contraintes inhérentes à un audit.
            
        </p>
        
        <p>
            
                L'audit ne pourra pas porter sur des informations non spécifiques au Responsable du Traitement, ceci afin de préserver la confidentialité des informations propres aux autres clients de Gimli ou des
                informations dont la divulgation serait susceptible de porter atteinte à la sécurité des autres clients et d'autres données à caractère personnel les concernant.
            
        </p>
        
        <p>
            
                Gimli s'engage à fournir à l'Utilisateur les résultats d'un audit externe indépendant sur les dispositifs opérationnels mutualisés (dispositifs de
                sécurité que nous avons mis en place pour l'ensemble de nos clients).
            
        </p>
        
        <p>
            
                Gimli s'engage à collaborer de bonne foi avec l'auditeur et à faciliter son audit, en lui apportant toutes les informations nécessaires et en répondant à toutes ses demandes
                relatives à l'audit.
            
        </p>
        
        <p>Un exemplaire du rapport d'audit rédigé par l'auditeur sera remis à chaque partie.</p>
        
        <p>
            
                Si les conclusions de l'audit contiennent des recommandations, leurs conditions de mise en &oelig;uvre seront étudiées contradictoirement dans les meilleurs délais entre le Responsable de
                traitement et Gimli.
            
        </p>
        
        <p>
            
                L'auditeur, personne physique désignée, sera d&ucirc;ment mandaté par écrit par le contractant, et sera soumis à la plus stricte confidentialité et au secret des affaires.
            
        </p>
        
        <h2 id="h.11p2ok10lk0t">11 - Modification de la Charte</h2>
        <p>Gimli se donne la possibilité de modifier sa Charte à tout moment, et procèdera à la publication de la version modifiée sur son Site.</p>
        
        <p>Dernière mise à jour : 06 Février 2023.</p>
        </div>
    );
}

export default PrivacyPolicy