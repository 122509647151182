import React, {useState} from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Modal from '../../elements/Modal';
import ContactUs from '../../sections/ContactUs';

const FooterNav = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-nav',
    className
  );

  const [contactModalActive, setContactmodalactive] = useState(false);

  const openContactModal = (e) => {
    e.preventDefault();
    setContactmodalactive(true);
  }

  const closeContactModal = (e) => {
    e.preventDefault();
    setContactmodalactive(false);
  }  

  const scroll = () => {
    window.scrollTo(0,0)
  }

  return (
    <nav
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
          <a href="https://www.linkedin.com/company/gimli-med/">LinkedIn</a>
        </li>
        <li>
          <Link aria-controls="contact-modal" onClick={openContactModal}>Contact</Link>
        </li>
        <li>
          <Link onClick={scroll} to="/legal-notice">Legal notice</Link>
        </li>
        <li>
          <Link onClick={scroll} to="/privacy-policy">Privacy policy</Link>
        </li>
        <li>
          <Link onClick={scroll} to="/terms-of-use">Terms of use</Link>
        </li>
      </ul>
      <Modal
          id="contact-modal"
          show={contactModalActive}
          handleClose={closeContactModal}
          style={{position: "fixed"}}
          >
            <ContactUs />
        </Modal>
    </nav>
  );
}

export default FooterNav;