import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Button from '../elements/Button';

const ContactUs = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_2ar2an5', 'template_rwmrb48', form.current, 'z0xBJBYjv85_f-H0H')
      .then((result) => {
          console.log(result.text);
          alert("Email sent! Thank you, we will get in touch soon.");
      }, (error) => {
          console.log(error.text);
          alert("An error occurred: send us an email at hello@gimli-med.io.");
      });
  };

  return (
    <>
      <form ref={form} onSubmit={sendEmail}>
        <label className="form-label">Name</label>
        <input className="form-input" type="text" name="user_name" />
        <label className="form-label">Email</label>
        <input className="form-input" type="email" name="user_email" />
        <label className="form-label">Message</label>
        <textarea className="form-input" name="message" rows="8" />
        <br />
        <Button type="submit" color="primary">Send</Button>
      </form>
    </>
  );
};

export default ContactUs;
