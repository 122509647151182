import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
    ...SectionTilesProps.types,
};

const defaultProps = {
    ...SectionTilesProps.defaults,
};

const Team = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    pushLeft,
    ...props
}) => {
const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
    );

    const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
    );

    // const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

    const sectionHeader = {
    title: "The Gimlis",
    paragraph: "",
};

return (
    <section {...props} className={outerClasses}>
        <div className="container">

        <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div
                className=""
                data-reveal-delay="200"
            >
            <div className="tiles-item-inner small">
                <Image
                    src={require("./../../assets/images/gimliTeamCompressed.jpg")}
                    alt="The Gimlis"
                />
            </div>
            </div>
        </div>
        </div>
    </section>
);
};

Team.propTypes = propTypes;
Team.defaultProps = defaultProps;

export default Team;
