import React, { useRef, useEffect} from 'react';
import { useLocation, Switch} from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import ReactGA from 'react-ga';
import { BrowserRouter } from 'react-router-dom';
// Layouts
import LayoutDefault from './layouts/LayoutDefault';
// Views 
import Home from './views/Home';
import LegalNotice from './views/LegalNotice'
import TermsOfUse from './views/TermsOfUse'
import PrivacyPolicy from './views/PrivacyPolicy'

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App = () => {

  const childRef = useRef();
  let location = useLocation();
  const onTop = () => {
    window.scrollTo({top: 0, behavior: "smooth"})
    // window.scrollTo(0, 0);
  }
  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add('is-loaded')
    childRef.current.init();
    trackPage(page);
    onTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <BrowserRouter>
          <Switch>
              <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
              <AppRoute exact path="/legal-notice" component={LegalNotice} layout={LayoutDefault} />
              <AppRoute exact path="/terms-of-use" component={TermsOfUse} layout={LayoutDefault} />
              <AppRoute exact path="/privacy-policy" component={PrivacyPolicy} layout={LayoutDefault} />
          </Switch>
        </BrowserRouter>
      )} />
  );
}

export default App;